import React from 'react';

import SEO from '../components/seo';

const About = () => (
	<section className="about">
		<SEO
			title="À propos"
			description=""
		/>

		<h2>À propos</h2>
		<p>Avec Concilio qu’elle a fondé en 2017, Nathalie Pichard gère avec et pour ses clients des projets qui font appel à son expérience de terrain du management, de l’organisation et de l’innovation, dans le domaine culturel et celui des hautes écoles.</p>
		<p>Elle bénéficie d’un large réseau suisse et international auquel elle fait appel pour réaliser les mandats qui lui sont confiés. Concilio collabore avec d’autres experts selon les projets.</p>
		<p>Après des études d’archéologie à l’Université de Lausanne complétée par une formation post-grade en muséologie, Nathalie prend la direction pendant douze ans du Musée romain de Lausanne-Vidy. Elle y dirige la construction du nouveau musée, y développe un programme d’expositions et d’événements tout en assurant la préservation et la mise en valeur des collections.</p>
		<p>Au sein de l’EPFL de 2000 à 2016, elle fait partie de l’équipe de direction en tant que Secrétaire générale pour les Affaires académiques. Elle assure la conduite des unités opérationnelles en charge de la formation et de la recherche ainsi que celle de nombreux projets stratégiques.</p>
		<p>Elle a co-fondé la Fondation ArtTech qu’elle dirige, et dont la mission est de promouvoir la recherche académique, l’innovation technologique, l’art et la culture, ainsi que d’encourager l’entrepreneuriat dans ce domaine.</p>
	</section>
);

export default About;
